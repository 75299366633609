import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const RegionNumbers = () => {
    const pageImage = useStaticQuery(graphql`
        query {
            headerImage: file(relativePath: {eq: "cloud.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                    }
                }
            },
        }
    `)
    return (
        <div className="background-color-cloud border-top-gold">
        <BackgroundImage
          Tag={`div`}
          className={`region-numbers image-max-width region-padding-large`}
          
          fluid={[pageImage.headerImage.childImageSharp.fluid, `linear-gradient(rgba(0,33,69,0.69), rgba(0,33,69,0.69))`].reverse()}
        >
          <div className="bound">
            <h2 className="text-color-white">Graduation in numbers</h2>
            <hr />
            <div className="slide-container">
              <div className="slide">
                <div className="slide-background">
                  <p className="intro">1916</p>
                  <p><b>Year of UBC&rsquo;s first graduation ceremony, which had 41 graduates</b></p>
                </div>
              </div>
              <div className="slide">
                <div className="slide-background">
                  <p className="intro">15,000</p>
                  <p><b>Number of Class of 2021 graduates from UBC Vancouver and UBC Okanagan</b></p>
                </div>
              </div>
              <div className="slide">
                <div className="slide-background">
                  <p className="intro">370,000+</p>
                  <p><b>Number of UBC alumni, in over 150 countries</b></p>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>
    )
}

export default RegionNumbers