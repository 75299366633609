import React from "react"
import Video from "./youtube"

const RegionSchedule = () => {
    return (
        <div className="region-schedule expand background-color-main region-padding-large">
          <div className="bound">
            <div className="row-fluid">
              <div className="span6">
                <p className="intro">The June 2, 2021, virtual graduation ceremony has concluded.</p>
                <p>Watch the video and share it with family and friends. Find the pre-recorded ceremony on <a href="https://youtu.be/Fj2BZZcH1KQ">YouTube</a>, <a href="https://www.facebook.com/events/842033593396072/">Facebook</a> or <a href="https://ubc.ca.panopto.com/Panopto/Pages/Viewer.aspx?id=9986fa05-373d-441b-80fc-ad3501590737">Panopto</a>, a platform that is accessible from many countries.</p>
                <p>This virtual ceremony celebrated the very real accomplishments of UBC graduates. Incorporating traditions from UBC&rsquo;s in-person ceremony, Chancellor Steven Point (xwĕ lī qwĕl tĕl) presided over the ceremony and UBC President and Vice-Chancellor Santa J. Ono addressed the Class of 2021. Shane Koyczan gave the keynote address.</p>
                <p>Looking for the UBC Okanagan graduation ceremony? <a href="https://virtualgraduation.ok.ubc.ca/">Watch it here</a>.</p>
              </div>
              <div className="span6">
                <Video 
                    videoSrcURL="https://www.youtube.com/embed/Fj2BZZcH1KQ"
                    videoTitle="UBC Virtual Graduation Ceremony"
                />
              </div>
            </div>
          </div>
        </div>
    )
}

export default RegionSchedule