import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

// Pre-Ceremony specific
// import RegionHero from "../../components/region_hero"
// import RegionSchedule from "../../components/region_schedule_pre_ceremony"

// Day of ceremony
// import RegionHero from "../../components/region_hero_day_of"
// import RegionSchedule from "../../components/region_schedule_day_of"

// Post ceremomny
import RegionHero from "../../components/region_hero_post"
import RegionSchedule from "../../components/region_schedule_post"

// import RegionCeremonyHighlights from "../../components/region_ceremony_highlights"
import RegionNumbers from "../../components/region_numbers"
import RegionCeremony from "../../components/region_ceremony"
import RegionWakelet from "../../components/region_wakelet"
import RegionGuestbook from "../../components/region_guestbook"
import RegionAcknowledgement from "../../components/region_acknowledgement"

import ogimage from "../../../static/images/ogimage.png"

const IndexPage = ( {location} ) => {
  return (
    <Layout>
      <Seo 
        title="UBC Vancouver Graduation: Spring 2021"
        description="The University of British Columbia’s virtual graduation ceremony for spring 2021."
        image={ogimage} />
      <RegionHero />
      <RegionSchedule />
      <RegionNumbers />
      <RegionCeremony />
      <RegionWakelet />
      <RegionGuestbook location={location} />
      <RegionAcknowledgement />
    </Layout>
  )
}

export default IndexPage