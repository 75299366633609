import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import EmbedCode from "./embed"
import GuestbookEntry from "./guestbook_entries"

import guestbook_icon from "../../static/images/guestbook_icon.png"


const RegionGuestbook = ( {location} ) => {

    const pageImage = useStaticQuery(graphql`
    query {
      guestbookImage: file(relativePath: {eq: "guestbook_image.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 569) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // ,
  //     guestbookIcon: file(relativePath: {eq: "guestbook_icon.png"}) {
  //       childImageSharp {
  //         fixed(width: 70) {
  //           ...GatsbyImageSharpFixed
  //         }
  //       }
  //     }

    return (
        <div className="region-guestbook-fallback background-color-ubc-blue region-padding-large">
            <div className="bound">
                <h2 className="text-color-white">Sign the Guestbook</h2>
                <div className="row-fluid">  
                    <div className="span6 padding-bottom-large-phone">
                    <div className="background-color-white border-top-gold override-padding">
                        <Img fluid={pageImage.guestbookImage.childImageSharp.fluid} />
                        <div className="sign-guestbook">
                        {/* <Img fixed={pageImage.guestbookIcon.childImageSharp.fixed} /> */}
                        <img src={guestbook_icon} alt="" width="70" />
                        <p>Congratulate your favourite UBC grad (or all UBC grads!). Here&rsquo;s your chance to recognize their accomplishments.</p>
                        <EmbedCode
                            EmbedURL="https://guestbook.graduation.ubc.ca/"
                            EmbedCodeTitle="Guestbook Embed"
                            EmbedCodeWrapperClass="responsive-media guestbook-iframe"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="span6">
                    <GuestbookEntry location={location} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegionGuestbook